import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './footer.scss';
import CookiesModal from '../cookies';

const Footer = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    // Structured data for the footer (Organization schema)
    const footerSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "MINE2 Technologies Pvt Ltd",
        "alternateName": "MINE2",
        "url": "https://www.mine2.io",
        "logo": "https://www.mine2.io/images/logo.webp",
        "description": "MINE2 provides a cyber deception platform with honeytokens and active defense to detect intrusions in 0 days.",
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Customer Service",
            "email": "support@mine2.io", // Replace with actual email
            "url": "https://www.mine2.io/contact"
        },
        "sameAs": [
            "https://www.x.com/mine2tech/",
            "https://www.linkedin.com/company/mine2-technologies/",
            "https://www.facebook.com/people/MINE2/100069623461291/",
            "https://www.instagram.com/mine2.io/"
        ]
    };

    return (
        <div>
            <footer className="footer-main">
                <div className="top-header">
                    <div className="container">
                        <div className="row">
                            {/* Logo and Branding Section */}
                            <div className="col-sm-4">
                                <div className="footer-logo">
                                    <NavLink to="/">
                                        <img
                                            src="/images/logo.webp"
                                            alt="MINE2 Cyber Deception Platform Logo"
                                            width="150"
                                            height="50"
                                            loading="lazy"
                                        />
                                    </NavLink>
                                    <p className="footer-description">
                                        MINE2 offers a cyber deception platform with honeytokens to detect intrusions in 0 days.
                                    </p>
                                </div>
                            </div>

                            {/* Useful Links Section */}
                            <div className="col-sm-4">
                                <h6>Useful Links</h6>
                                <ul>
                                    <li><NavLink to="/about">About Us</NavLink></li>
                                    <li><NavLink to="/career">Careers</NavLink></li>
                                    <li><NavLink to="/contact">Contact Us</NavLink></li>
                                    <li><NavLink to="/faq">FAQ</NavLink></li>
                                    {/*<li><NavLink to="/services">Services</NavLink></li>*/}
                                    {/*<li><NavLink to="/blog">Blog</NavLink></li>*/}
                                </ul>
                            </div>

                            {/* Social Links Section */}
                            <div className="col-sm-4">
                                <h6>Follow Us</h6>
                                <div className="startupIndiaInfo">
                                    <div className="icon-media">
                                        <a
                                            href="https://www.x.com/mine2tech/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Follow MINE2 on Twitter"
                                        >
                                            <img
                                                src="/images/twitter.webp"
                                                alt="MINE2 Twitter Profile"
                                                width="30"
                                                height="30"
                                                loading="lazy"
                                            />
                                        </a>
                                        <a
                                            href="https://www.linkedin.com/company/mine2-technologies/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Follow MINE2 on LinkedIn"
                                        >
                                            <img
                                                src="../images/linkedin-log-type.webp"
                                                alt="MINE2 LinkedIn Profile"
                                                width="30"
                                                height="30"
                                                loading="lazy"
                                            />
                                        </a>
                                        {/*<a*/}
                                        {/*    href="https://www.facebook.com/people/MINE2/100069623461291/"*/}
                                        {/*    target="_blank"*/}
                                        {/*    rel="noopener noreferrer"*/}
                                        {/*    aria-label="Follow MINE2 on Facebook"*/}
                                        {/*>*/}
                                        {/*    <img*/}
                                        {/*        src="/images/facebook.webp"*/}
                                        {/*        alt="MINE2 Facebook Profile"*/}
                                        {/*        width="30"*/}
                                        {/*        height="30"*/}
                                        {/*        loading="lazy"*/}
                                        {/*    />*/}
                                        {/*</a>*/}
                                        <a
                                            href="https://www.instagram.com/mine2.io/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Follow MINE2 on Instagram"
                                        >
                                            <img
                                                src="/images/instagram.webp"
                                                alt="MINE2 Instagram Profile"
                                                width="30"
                                                height="30"
                                                loading="lazy"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bottom Footer Section */}
                <div className="bottom-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="privacy">
                                    &copy; 2025 MINE2 Technologies Pvt Ltd. All rights reserved.
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="privacy" style={{ textAlign: "right" }}>
                                    <NavLink to="/terms">Terms and Conditions</NavLink> |{" "}
                                    <NavLink to="/privacy">Privacy Policy</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Structured Data for Footer */}
                <script type="application/ld+json">
                    {JSON.stringify(footerSchema)}
                </script>
            </footer>
            <CookiesModal />
        </div>
    );
};

export default Footer;