import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './career.scss';
import Header from '../header';
import Footer from '../footer';
import JobFilter from '../jobFilter';

const Career = () => {

    return (
        <div>
            <Header/>
            <div className="container">
                <div className="banner-info">
                    <div className="content">
                        <div className="row show_my_content">
                            <div className="col-sm-8">
                                <h1>Careers</h1>
                                <p>Work at the most dynamic company & unlock your true potential.</p>
                            </div>
                            <div className="col-sm-4">
                                <img src="../images/career.webp" loading="lazy" alt={"career"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container marketing">
                <div className="row featurette">
                    <div className="col-md-5">
                        <img src="../images/bg.webp" alt="about" loading="lazy"/>
                    </div>
                    <div className="col-md-7">
                        <h6>Career at MINE2</h6>
                        <h1>Your Life at MINE2</h1>
                        <p>At MINE2 we believe in working together and working hard. With so many happy clients, we are
                            looking for dynamic and creative individuals who are willing to dedicate themselves to
                            providig innovative products and services for our clients.</p>
                    </div>
                </div>
            </div>
            <div className="container marketing">
                <div className="row featurette" margin="0">
                    <div className="col-md-7">
                        <h6>We're Hiring</h6>
                        <h1>Become one of us?</h1>
                        <p>For all positions, keep in mind that your job will impact so many clients. If you have
                            the skills and think you will thrive on this challenge, we really look forward to
                            meeting you!</p>
                        <h3>Send Your Resume</h3>
                        <h5>hr@mine2.com</h5>
                    </div>
                    <div className="col-md-5">
                        <img src="../images/rightCareer.webp" loading="lazy" alt={'career'}/>
                    </div>
                </div>
            </div>
            <section className='outerBgDataHr'>
                <div className="container">
                    <div className="row featurette">
                        <JobFilter/>
                    </div>
                </div>
            </section>
            {/*{modal &&*/}
            {/*  <div className='thanksYou'>*/}
            {/*      <div className="wrapper-2">*/}
            {/*      <h1>Sorry! &#128542;</h1>*/}
            {/*      <p>Registrations are closed</p>*/}
            {/*      <NavLink className="nav-link" to="/"> <button className="go-home">*/}
            {/*      go home*/}
            {/*      </button>*/}
            {/*      </NavLink>*/}
            {/*      </div>*/}
            {/*  </div>*/}
            {/*  }*/}
            <Footer/>
        </div>
    );
}

export default Career;