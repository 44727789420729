import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';
import './header.scss';
import { Helmet } from "react-helmet";

const Header = () => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "MINE2: Cyber Deception Platform",
        "alternateName": "MINE2",
        "url": "https://www.mine2.io",
        "logo": "https://www.mine2.io/images/logo.webp",
        "description": "MINE2 is a SaaS cyber deception platform that provides honeytokens, decoys, and active defense to detect intrusions in 0 days.",
        "applicationCategory": "Security Software",
        "operatingSystem": "All",
        "offers": {
            "@type": "Offer",
            "price": "Contact for pricing",
            "priceCurrency": "USD"
        },
        "sameAs": [
            "https://www.facebook.com/people/MINE2/100069623461291/",
            "https://www.instagram.com/mine2.io/",
            "https://www.linkedin.com/company/mine2-technologies/",
        ]
    };

    // Google Analytics script (unchanged)
    const gtagScript = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-RZL6LMYG8F');
    `;

    return (
        <Fragment>
            <Helmet>
                <title>MINE2: Cyber Deception Platform | Honeytokens & Active Defense</title>

                <meta
                    name="description"
                    content="MINE2 offers a SaaS cyber deception platform with honeytokens and active defense to detect intrusions in 0 days. Secure your network with advanced threat detection."
                />

                <meta
                    name="keywords"
                    content="cyber deception, honeytokens, active defense, SaaS cybersecurity, intrusion detection, zero-day threat detection, MINE2, digital mines"
                />

                <link rel="canonical" href="https://www.mine2.io" />

                <meta property="og:title" content="MINE2: Cyber Deception Platform | Honeytokens & Active Defense" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.mine2.io" />
                <meta
                    property="og:description"
                    content="Protect your network with MINE2’s cyber deception platform. Use honeytokens and active defense to detect intrusions in 0 days."
                />
                <meta property="og:image" content="https://www.mine2.io/images/logo.webp" />
                <meta property="og:image:secure_url" content="https://www.mine2.io/images/logo.webp" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="628" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:alt" content="MINE2 Cyber Deception Platform Logo" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="MINE2: Cyber Deception Platform | Honeytokens & Active Defense" />
                <meta name="twitter:url" content="https://www.mine2.io" />
                <meta name="twitter:domain" content="mine2.io" />
                <meta
                    name="twitter:description"
                    content="MINE2 provides a  cyber deception platform with honeytokens to detect intrusions in 0 days. Secure your network today."
                />
                <meta name="twitter:image" content="https://www.mine2.io/images/logo.webp" />
                <meta name="twitter:image:alt" content="MINE2 Cyber Deception Platform Logo" />
                <meta name="twitter:creator" content="@mine2tech" />

                <meta name="robots" content="index, follow" />

                <meta httpEquiv="content-language" content="en-us" />

                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>

                <script async src="https://www.googletagmanager.com/gtag/js?id=G-RZL6LMYG8F"></script>
                <script>{gtagScript}</script>
            </Helmet>

            <StickyHeader
                header={
                    <header className="header-top">
                        <nav id="navbar_top" className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container">
                                <NavLink className="navbar-brand" to="/">
                                    <img
                                        src="/images/logo.webp"
                                        className="App-logo"
                                        alt="MINE2 Cyber Deception Platform Logo"
                                    />
                                </NavLink>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto action-buttons">
                                        <li className="nav-item">
                                            <NavLink
                                                exact
                                                activeClassName="active"
                                                className="nav-link"
                                                to="/"
                                            >
                                                Home <span className="sr-only">(current)</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <div>
                                                <a
                                                    className="nav-link"
                                                    href="https://console.mine2.io/sign-in"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Product
                                                </a>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                activeClassName="active"
                                                className="nav-link"
                                                to="/about"
                                            >
                                                About Us
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                activeClassName="active"
                                                className="nav-link"
                                                to="/faq"
                                            >
                                                FAQ
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                activeClassName="active"
                                                className="nav-link"
                                                to="/contact"
                                            >
                                                Contact Us
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </header>
                }
            />
        </Fragment>
    );
};

export default Header;