import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './faq.scss';
import Header from "../header";
import Footer from "../footer";
import faq from './faq.json';

const FAQ = () => {
    // const [faqData, setFaqData] = useState([]);
    //
    // useEffect(() => {
    //     fetch('./faq.json')
    //         .then(response => response.json())
    //         .then(data => setFaqData(data))
    //         .catch(error => console.error('Error fetching FAQ data:', error));
    // }, []);

    return (
        <div>
            <Header/>
            <div className="container" id={"faqAccordion"}>
                <div className="banner-info">
                    <div className="content show_my_content">
                        <h1 className="text-center">FAQs</h1>
                        <div className="accordion" style={{pointerEvents: "auto"}}>
                            {faq.map((faq, index) => (
                                <div className="accordion-item custom-accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`heading${index}`}>
                                        <button
                                            className="accordion-button custom-accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${index}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse${index}`}
                                        >
                                            {faq.question}
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse${index}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body custom-accordion-body">
                                            {faq.answer}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default FAQ;