import React from 'react';
import './slider.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Slider() {
    return (<div className='outerMainOuter'>
            <div className='videoOuterInfo'>
                <div className="elementor-background-video-embed"></div>
                <video
                    src="/images/landing_video.mp4"
                    preload="auto"
                    autoPlay
                    loop
                    muted
                    style={{width: '100%', height: '121.5%'}}
                    title="Mine2 Landing"
                    controls={false}
                ></video>
            </div>
            <div className='contentCenter'>
                <h3>Digital Mines</h3>
            </div>
            <div className='contentCenter'
                 style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '55%'}}>
                <p>Detecting Intrusions in 0 Days</p>
            </div>
        </div>
    );
}

export default Slider;