import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './about.scss';
import Header from '../header';
import Footer from '../footer';

const About = () => {
    return (
        <div>
            <Header/>
            <div className="container">
                <div className="banner-info">
                    <div className="content show_my_content">
                        <div className="row">
                            <div className="col-sm-8">
                                <h1>We are MINE2</h1>
                                <p>At MINE2, We Bringing in the World's First Ever Digital Landmines</p>
                            </div>
                            <div className="col-sm-4">
                                <img src="../images/bg.webp" alt="about" loading={"lazy"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container marketing">
                <div className="row featurette">
                    <div className="col-md-12 dataInfoCnt">
                        <h6>About Us</h6>
                        <p>After spending a decade in cyber security, hacking and securing hundreds of organizations
                            across the world including Banks, E-commerce, Government, Fortune Companies, Startups and
                            other giants - we realized the gap which was not so easy to fill. There is always this
                            constant need to create layers of defense for hackers yet there is little done to catch it.
                            To fill this, we have come up with World's first digital Landmines.</p>
                        <p>With this, we aim to establish a layer of deception which can be implemented in a single
                            click! A layer which helps organizations to not only defend but capture the intruders in
                            shortest time while also helping in identifying the breach areas.</p>
                        <p> We are committed to level up the deception game to a point where an attacker fears to
                            compromise the machine, because what may lie ahead can be totally illusive! There was an era
                            when landmines changed the war game and now we are weaponizing you with what will change the
                            cyber warfare truly.</p>
                        <br/>
                        <p>Trust us enough or <a href="mailto:info@mine2.io">Contact us</a> to witness it yourself </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default About;