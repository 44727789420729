import React from 'react';
import './testimonialClient.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import data from './data.json';

function TestimonialsClient() {
    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        loop: true,
        dots: true,
        smartSpeed: 500,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,
                smartSpeed: 500
            }
        },
    };

    return (
        <div className='owlDataclient'>
            <OwlCarousel className='owl-theme' items="1" loop margin={10} dots nav  {...options}>
                {data.map((item, index) => (
                    <div className='item' key={index}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                            <div className='cardSectionInfo'>
                            <img alt={item.description} fetchpriority="high" src={item.image} style={{maxHeight:"250px", display: "block", margin: item.margin}}/>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </div>
                        </a>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    );
}

export default TestimonialsClient;