import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './privacy.scss';
import Header from '../header';
import Footer from '../footer';

const Privacy = () => {
    return (
        <div>
            <Header/>
            <div className="container">
                <div className="banner-info">
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-8">
                                <h1>Privacy Policy</h1>
                                <p>Last updated on November 2, 2024</p>
                            </div>
                            <div className="col-sm-4">
                                <img src="../images/privacy.webp" alt="Privacy"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container marketing">
                <div className="row featurette">
                    <div className="col-sm-12">
                        <div className="info-hsn">
                            <h2>1. Privacy Policy</h2>
                            <h3>1.1. Introduction</h3>
                            <p>At Mine2 Group, we’re committed to protecting and respecting your privacy. This privacy
                                policy will help you understand how we may collect and use your personal information.
                                This policy also describes your rights and how you can use your rights. You can easily
                                contact Mine2 if you have any questions related to this policy.</p>
                            <p>This Policy explains when and why we collect personal information about people who visit
                                our website, how we use it, the conditions under which we may disclose it to others and
                                how we keep it secure. We may change this Policy from time to time so please check this
                                page occasionally to ensure that you’re happy with any changes. By using our website,
                                you’re agreeing to be bound by this Policy.</p>
                        </div>
                        <div className="info-hsn">
                            <h3>1.2. Who are we?</h3>
                            <p>We’re Mine2 Group, the world’s largest independent software testing company. Mine2 is
                                considered Data Controller of your personal data. The Mine2 Group comprises of offices
                                in the North America, UK and Europe, Middle East and India.</p>
                            <p>References to “Mine2” on this site should be considered as references to Mine2 Group and
                                all of its subsidiaries.</p>
                            <p>Any questions regarding this Policy and our privacy practices should be sent by email to
                                compliance@Mine2group.com or by writing to our registered office, Equitable House, 47
                                King William Street, London, England, EC4R 9AF. Alternatively, you can telephone +44
                                (0)1772 888344.</p>
                        </div>
                        <div className="info-hsn">
                            <h3>1.3. How do we collect information from you?</h3>
                            <p>We obtain information about you when you use our website, for example, when you contact
                                us about products and services, sign up to webinars and events, marketing information or
                                to register your interest in a career at Mine2 Group.</p>
                        </div>
                        <div className="info-hsn">
                            <h3>1.4. What personal data do we process and from whom is such data collected?</h3>
                            <p>The type of personal data that Mine2 processes about you may be:</p>
                            <p>Your contact details, such as name, address, telephone number and email address</p>
                            <p>Your job title, position including preferences and interests in a professional context
                                and your company’s name</p>
                            <p>Website traffic information as provided by your web browser such as browser type,
                                language and the address of the referring website and other traffic information such as
                                IP address</p>
                            <p>Website visitor behavior such as which links you click and when</p>
                            <p>Any other information that you provide to use when you are communicating with us</p>
                            <p>Mine2 may collect personal data directly from you when you make purchases of our products
                                and services, you request support for a product or service or when you submit questions
                                or comments to us.</p>
                            <p>On the Mine2 Group website you can subscribe to our newsletters, sign up to our events
                                and fill in a contact request. In general, Mine2 collects personal data directly from
                                you when you register on our site or fill out a form. We may also, with your consent,
                                use cookies and other tracking technology when you use our websites in order to optimize
                                your experience of these.</p>
                            <p>We may also collect information about you from other sources, including publicly
                                available databases or third parties from whom we have purchased data or to whom you
                                have provided your data, and combine this data with information we already have about
                                you. We may also receive information from other affiliated companies that are a part of
                                our corporate group. This helps us to update, expand and analyze our records, identify
                                new prospects for marketing, and provide information about our products and services
                                that may be of interest to you.</p>
                        </div>
                        <div className="info-hsn">
                            <h3>1.5. How is your information used?</h3>
                            <p>Mine2 may save and process your personal data in order to:</p>
                            <p>Process requests for information that you have submitted. Accordingly, if you do not
                                provide the requested personal information, Mine2 will not be able to respond to a
                                contact request or to send you any newsletters or invitations and information</p>
                            <p>To carry out our obligations arising from any contracts entered into by you and us</p>
                            <p>Dealing with applications into a webinar or other event</p>
                            <p>Seek your views or comments on the services we provide</p>
                            <p>Notify you of changes to our services</p>
                            <p>For marketing and market research, as well as basis for Mine2’s market and customer
                                analyses, business and product development, and statistics</p>
                            <p>Send you communications which you have requested and that may be of interest to you.
                                These may include information about campaigns, promotions of our products and
                                services</p>
                            <p>Process a job application</p>
                            <p>To personalize your experience (your information helps us to better respond to your
                                individual needs)</p>
                            <p>To improve our website (we continually strive to improve our websites offerings based on
                                the information and feedback we receive from you)</p>
                            <p>To allow us to provide, maintain, monitor, improve and develop our business and services
                                and to personalise our services for you</p>
                        </div>
                        <div className="info-hsn">
                            <h3>1.6. How long do we store your personal data?</h3>
                            <p>We review our retention periods for personal information on a regular basis. We are
                                legally required to hold some types of information to fulfil our statutory obligations.
                                Mine2 will only retain your personal data for as long as necessary for the stated
                                purpose, while also taking into account our need to answer queries or resolve problems
                                and to comply with legal requirements under applicable law. This means that we may
                                retain your personal data for a reasonable period after your last interaction with us
                                (normally for a period of three calendar years from your last interaction with us but if
                                you are representing a customer of Mine2, we may keep your information for the duration
                                of the contractual relationship and to the extent permitted also after the end of that
                                relationship for as long as necessary to perform the purpose). When the personal data
                                that we collect is no longer required in this way, we destroy or delete it in a secure
                                manner in accordance with Mine2 information security policies.</p>
                            <p>Personal data provided in connection with newsletter subscriptions, event registrations
                                or information requests are stored by Mine2 until you unsubscribe from the applicable
                                service. However, if you unsubscribe, Mine2 will continue to process your personal data
                                to the extent necessary to ensure by technical means that no further posting of
                                newsletters, event invitations and similar are sent to you. If Mine2 does not save your
                                personal data in this respect, we will not be able to ensure that no further
                                newsletters, invitations or information will be sent to you. The continued processing of
                                your personal data is, according to Mine2’s assessment, necessary for the purposes of
                                our legitimate interest in preventing sending of newsletters, information and
                                invitations to you in accordance with your expressed desire.</p>
                        </div>
                        <div className="info-hsn">
                            <h3>1.7. Who has access to your information?</h3>
                            <p>Mine2 will not sell or rent your information to third parties and we will not share your
                                information with third parties for marketing purposes.</p>
                            <p>Third Party Service Providers working on our behalf: We may pass your information to our
                                third-party service providers, agents subcontractors and other associated organisations
                                for the purposes of completing tasks and providing services to you on our behalf (for
                                example to process mailings that are sent out by Mine2 Group). However, when we use
                                third party service providers, we disclose only the personal information that is
                                necessary to deliver the service and we have a contract in place that requires them to
                                keep your information secure and not to use it for their own direct marketing purposes.
                                Please be reassured that we will not release your information to third parties beyond
                                the Mine2 Group Network for them to use for their own direct marketing purposes, unless
                                you have requested us to do so, or we are required to do so by law, for example, by a
                                court order or for the purposes of prevention of fraud or other crime.</p>
                            <p>Mine2 may also share your personal data in connection with mergers, acquisitions or
                                divestiture of all or parts of Mine2’s business, where the acquiring entity as well as
                                its consultants and Mine2’s own consultants may obtain access to data managed by
                                Mine2.</p>
                        </div>
                        <div className="info-hsn">
                            <h3>1.8. Security</h3>
                            <p>Mine2 takes security seriously. We take various steps to protect information you provide
                                to us from loss, misuse, and unauthorized access or disclosure. These steps take into
                                account the sensitivity of the information we collect, process and store, and the
                                current state of technology.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>2. Your Rights</h2>
                            <p>You have a choice about whether or not you wish to receive information from us. If you do
                                not want to receive direct marketing communications from us about our exciting products,
                                services and promotions, then you can select your choices by ticking the relevant boxes
                                situated on the form on which we collect your information. If you change your mind, you
                                can unsubscribe using the links that will be supplied on each and every subsequent
                                mailings that you receive, you will always have the option to unsubscribe.</p>
                            <p>You also have the right to request that Mine2 corrects any inaccuracies in your personal
                                data and that Mine2 shall erase your personal data or restrict the processing of your
                                personal data. You are also finally entitled to lodge a complaint regarding Mine2’s
                                processing of your personal data with a supervisory authority. You can contact Mine2 for
                                more information about these rights.</p>
                        </div>
                        <div className="info-hsn">
                            <h3>2.1. How you can access and update your information</h3>
                            <p>The accuracy of your information is important to us. We’re working on ways to make it
                                easier for you to review and correct the information that we hold about you. In the
                                meantime, if you change email address, or any of the other information we hold is
                                inaccurate or out of date, please email us at: compliance@Mine2group.com, or telephone
                                on +44 (0)1772 888344.</p>
                            <p>You have the right to ask for a copy of the information Mine2 Group hold about you.
                                Security precautions in place to protect the loss, misuse or alteration of your
                                information.</p>
                            <p>When you give us personal information, we take steps to ensure that it’s treated
                                securely. Mine2 Group currently holds ISO27001 certifications across multiple regions
                                (including UK, US and India).</p>
                            <p>Non-sensitive details (your email address etc.) are transmitted normally over the
                                Internet, and this can never be guaranteed to be 100% secure. As a result, while we
                                strive to protect your personal information, we cannot guarantee the security of any
                                information you transmit to us, and you do so at your own risk. Once we receive your
                                information, we make our best effort to ensure its security on our systems.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>3. E-mails</h2>
                            <p>Where there is an instance in which an e-mail sent to or from Mine2 contains personal
                                data, the further processing of such e-mail means that Mine2 will process personal data.
                                E-mails almost always contain personal data because the e-mail address itself is usually
                                considered as personal data. The e-mail may also contain other information which
                                combined will be considered as personal data.</p>
                            <p>The content of incoming e-mails are usually unknown when the email is received by us,
                                when this is the case, the personal data contained in the e-mail is processed by Mine2,
                                but for the purpose of reading the e-mail to assess if the e-mail shall be deleted or if
                                Mine2 is needed to take action. If, after receipt of an incoming e-mail, Mine2 considers
                                that the e-mail should not be deleted, and that further processing is necessary, Mine2
                                will on a case-by-case basis decide the legal basis, means and period for the
                                processing. The legal basis for the processing of e-mails depends among other things on
                                the content of the e-mail and whether Mine2 has any relationship with the
                                recipient/sender.</p>
                            <p>If, upon receipt/dispatch of an e-mail, Mine2 determines that the e-mail shall be
                                deleted, deletion will be made within a reasonable time after receipt/dispatch.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>4. Profiling</h2>
                            <p>We may analyse your personal information to create a profile of your interests and
                                preferences so that we can contact you with information regarding Mine2 Group products
                                and services that are relevant to you. We may make use of additional information about
                                you when it is available from external sources to help us do this effectively.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>5. Use of ‘cookies’</h2>
                            <p>Like many other websites, the Mine2 Group website uses cookies. ‘Cookies’ are small
                                pieces of information sent by an organisation to your computer and stored on your hard
                                drive to allow that website to recognise you when you visit. They collect statistical
                                data about your browsing actions and patterns and do not identify you as an individual.
                                For example, we use cookies to store your country preference. This helps us to improve
                                our website and deliver a better more personalised service.</p>
                            <p>It is possible to switch off cookies by setting your browser preferences. Turning cookies
                                of may result in a loss of functionality when using our website.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>6. Links to other websites</h2>
                            <p>Our website may contain links to other websites run by other organisations. This privacy
                                policy applies only to Mine2’s website‚ so we encourage you to read the privacy
                                statements on the other websites you visit. We cannot be responsible for the privacy
                                policies and practices of other sites even if you access them using links from our
                                website.</p>
                            <p>In addition, if you linked to our website from a third-party site, we cannot be
                                responsible for the privacy policies and practices of the owners and operators of that
                                third-party site and recommend that you check the policy of that third-party site.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>7. 16 or Under</h2>
                            <p>We are concerned to protect the privacy of children aged 16 or under. If you are aged 16
                                or under‚ please get your parent/guardian’s permission beforehand whenever you provide
                                us with personal information.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>8. Transferring your information outside of Europe</h2>
                            <p>As part of the services offered to you through this website, the information which you
                                provide to us may be transferred to countries outside the European Union (“EU”). By way
                                of example, this may happen if any of our servers are located in a country outside of
                                the EU. These countries may not have similar data protection laws to the UK. By
                                submitting your personal data, you’re agreeing to this transfer, storing or processing.
                                If we transfer your information outside of the EU in this way, we will take the
                                necessary steps to ensure that appropriate security measures are taken with the aim of
                                ensuring that your privacy rights continue to be protected as outlined in this
                                Policy.</p>
                            <p>If you use our services while you are outside the EU, your information may be transferred
                                outside the EU in order to provide you with those services. In cases where Mine2 exports
                                your personal data outside the EEA, such export is based either on a decision by the EU
                                Commission that the third country in question ensures an adequate level of protection,
                                or on appropriate safeguards to ensure that your rights are protected. Examples of
                                appropriate protection measures are approved code of conduct in the recipient country,
                                standard contract clauses, binding company internal rules or Privacy Shield.</p>
                        </div>
                        <div className="info-hsn">
                            <h2>9. Review of this Policy</h2>
                            <p>We may change this policy from time to time and if we do we will post any changes on this
                                page. If you continue to interact with us after those changes are in effect, you are
                                agreeing to the revised policy.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Privacy;