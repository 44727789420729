import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import ReactFluidAnimation from '@usertive/react-fluid-animation';

ReactDOM.render(
    <BrowserRouter>
      <App/>
      <ReactFluidAnimation  className="animationData"/>
     </BrowserRouter>,
  document.getElementById('root')
);
